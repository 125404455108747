<template>
  <div class="home" v-if="pageData">
    <div class="intro-full-w" >
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
      <div class="two-column-layout section">
        <div class="container">
        <div class="row mt-5" >
            <div class="col-lg-6 order-lg-1 order-md-2 text">
            <h6>
                ABOUT US
            </h6>
            <h3 class="light">
                {{this.pageData.about_us.about_us_titolo}}
            </h3>
            <p v-html="this.pageData.about_us.about_us_testo_1"></p>
            </div>
            <div class="col-lg-6 order-lg-2 order-md-1 image">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6 order-lg-2 order-md-1 text">
            <p v-html="this.pageData.about_us.about_us_testo_2"></p>
            <router-link class="read-more" to="/who-we-are">read more</router-link>
            </div>
            <div class="col-lg-6 order-lg-1 order-md-2 image">
            </div>
        </div>
        </div>
    </div>
    <div class="section course">
      <div class="container">
        <div class="row">
        <div class="col-lg-6 image d-none d-lg-block">
        </div>
        <div class="col-lg-6 list">
          <div class="intro">
            <h6>the course</h6>
            <h3 class="light">Our Courses</h3>
            <p v-html="this.pageData.corsi.corso_testo"></p>
          </div>
          <router-link to="/presentation">
            <div>
              <a class="arrow"></a>
              <h6>COURSE PART 1</h6>
              <p>{{ this.pageData.corsi.parts.corso_pt1_durata }}</p>
              <h3 class="light">{{ this.pageData.corsi.parts.corso_pt1_titolo }}</h3>
              <p v-html="this.pageData.corsi.parts.corso_pt1_testo"></p>
            </div>
          </router-link>
          <router-link to="/presentation" >
            <div class="grey">
              <a class="arrow"></a>
              <h6>COURSE PART 2</h6>
              <p>{{ this.pageData.corsi.parts.corso_pt2_durata }}</p>
              <h3 class="light">{{ this.pageData.corsi.parts.corso_pt2_titolo }}</h3>
              <p v-html="this.pageData.corsi.parts.corso_pt2_testo"></p>
            </div>
          </router-link>
          <router-link to="/presentation" >
          <div>
            <a class="arrow"></a>
            <h6>COURSE PART 3</h6>
            <p>{{ this.pageData.corsi.parts.corso_pt3_durata }}</p>
            <h3 class="light">{{ this.pageData.corsi.parts.corso_pt3_titolo }}</h3>
            <p v-html="this.pageData.corsi.parts.corso_pt3_testo"></p>
          </div>
          </router-link>
        </div>
        </div>
      </div>
    </div>
    <div class="section schoolarship">
      <div class="container">
        <h6>
          ABOUT US
        </h6>
        <h3 class="light">{{ this.pageData.scolarship.scolarship_titolo }}</h3>
        <p v-html="this.pageData.scolarship.scolarship_testo"></p>
        <div class="buttons-container">
        <router-link to="/how-apply">
          <button class="cta-big dark-bg icon-book m-lg-3">
            <label class="top">MHPC FOR STUDENTS</label>
            <label class="bottom">How to Apply</label>
          </button>
        </router-link>
        <router-link to="/organizations">
          <button class="cta-big dark-bg icon-university m-lg-3">
            <label class="top">MHPC for organizations</label>
            <label class="bottom">How to become sponsor</label>
          </button>
        </router-link>
        </div>
      </div>
    </div>
    <div class="section careers offset-tiles ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-lg-1 image ">
            <img src="../assets/home-careers.jpg" alt="">
          </div>
          <div class="col-lg-6 order-lg-2 d-flex flex-column justify-content-center text">
            <div>
              <h6>success stories</h6>
              <h3 class=light>{{this.pageData.careers.careers_titolo}}</h3>
              <p v-html="this.pageData.careers.careers_testo"></p>
              <router-link class="read-more white" to="/success-stories">read more</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news section">
      <div class="container">
          <h6 class="mb-sm-0">
          News
        </h6>
        <h3 class="light ">
          Latest News & Events
        </h3>
        <div class="latest-news mt-lg-5 mt-md-4 mt-4">
          <div class="row">
              <div :class="['col-xl-4 col-lg-6 post', post.type]" v-for="(post, index) of posts.slice(0, 3)" :key="index">
              <div class="post-image-wrapper">
                <router-link v-if="post.type === 'node--news'"  :to="postLink(post,'news')">
                  <div class="post-image" :style="getStylePostImage(post)"></div>
                </router-link> 
                <router-link v-else-if="post.type === 'node--event'" :to="postLink(post,'event')">
                  <div class="post-image" :style="getStylePostImage(post)"></div>
                </router-link> 
              </div>
              <p class="date">
                <span class="label" v-if="post.type === 'node--news'"> NEWS </span>
                <span class="label" v-else-if="post.type === 'node--event'"> EVENT </span><span>{{post.date}}</span>
              </p>
                <router-link v-if="post.type === 'node--news'" :to="postLink(post,'news')">
              <h5 class="mb-xl-3 mt-xl-4">
                {{post.attributes.title}}
              </h5>
              </router-link> 
              <router-link v-else-if="post.type === 'node--event'" :to="postLink(post,'event')">
              <h5 class="mb-xl-3 mt-xl-4">
                {{post.attributes.title}}
              </h5>
              </router-link> 

              <p class="text" v-html="post.attributes.field_shared_description.value">
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <router-link :to="'/news-and-events/'" class="read-more d-inline-block mt-xl-5">
              read more
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-else></div>
</template>
<script>
import { slugify } from "@/libs/utils";
import IntroPage from '../components/IntroPage.vue'

export default {
    name:"home",
    components: {
        IntroPage
    },
    data(){
        return {
            pageData:null,
            currentPageId:"41043545-0413-4fcd-a63a-4d36a155c9a6",
            newsData:[],
            eventsData:[],
            posts:[]
        }
    },
    methods:{
        postLink(post, prefix) {
      let postSlug = slugify(post.attributes.title);
      return `/${prefix}/${postSlug}-${post.attributes.drupal_internal__nid}`;
    },
      fetchNews(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/news`, {
          method: "GET",
          mode: "cors",
        })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.newsData = json.data;
          this.mergePosts();
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      },
      fetchEvents(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/event`, {
          method: "GET",
          mode: "cors",
        })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.eventsData = json.data;
          this.mergePosts();
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      },
      formatDate(dateString) {
        return new Date(dateString).toLocaleDateString("en", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
    },
    getStylePostImage(post) {
      let style = {};
      if (post.image) {
        style.backgroundImage =
          " url(" + process.env.VUE_APP_ENDPOINT + post.image.url + ")";
      }
      return style;
    },
    mergePosts(){
      if (this.newsData && this.eventsData) {
        // unisci array news e eventi e ordina per data
        let posts = this.newsData.concat(this.eventsData).sort((a, b) => {
          return (
            new Date(b.attributes.field_news_date) -
            new Date(a.attributes.field_news_date)
          );
        });
        posts = posts.map((post) => {
          post.date = this.formatDate(post.attributes.field_news_date);
          return post;
        });
        // fetch immagine per ogni post
        posts.forEach((post) => {
          if (post.relationships.field_shared_image.data) {
            var imgId = post.relationships.field_shared_image.data.id;
            fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/file/file/${imgId}`)
              .then((r) => r.json())
              .then((response) => {
                var posts = Object.assign([], this.posts);
                posts.forEach((p) => {
                  if (p.id === post.id) {
                    p.image = {
                      url: response.data.attributes.uri.url,
                    };
                  }
                });
                this.posts = posts;
              });
          }
          return post;
        });

        this.posts = posts;
        }
      },
      fetchPageData(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/homepage`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.pageData = json.data;
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      },
    },
    mounted(){
      this.fetchPageData()
      this.fetchNews()
      this.fetchEvents()
    }
};
</script>
<style lang="scss" >



  


</style>